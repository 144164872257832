$primary-color: #70BF44;
$dark-primary-color: #427f20;
$sets-orange: #e7a11d;
$background-color: #eee;
$blue-color: #4f9bb9;
$light-color: #fff;
$dark-color: #333;
$box-shadow: 0px 0px 5px rgba(0,0,0,0.4);
$sets-laromedel-petrol: #005464;
$sets-laromedel-lightblue: #DFEFF2;

body {
   color: $dark-color;
   padding-top: 80px;
}
.navbar-licensify {
    background: $blue-color;
    color: $light-color;
    a.navbar-brand {
        img {
            height: 20px;
        }
    }
    .nav-item a {
        color: #fff;
    }
    a.dropdown-item {
        color: #444;
    }
}

.header-row {
    padding: 20px 0;
}

.licensify {
    margin: 0 0 30px 0;
    max-width: 300px;
}
.main-row {
    padding: 40px 15px;
}
.light-bg {
    background: $light-color;
}
.footer {
    padding: 40px 0;
    text-align: center;
    color: #666;
}
/* Login */

h1 {
    text-transform: uppercase;
}
.sets-logo {
    width: 50px;
}
.signin {
    padding: 0 0 100px 0;
}
.teacher {
    background: $blue-color;
    padding: 80px 0;
    color: #fff;
}
.about {
    background: $sets-orange;
    padding: 40px 0;
    color: #fff;
    .about-content {
        text-align: center;
    }
}



.col-signin {
    background: #fff;
    padding: 40px;
    width: 350px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.4);
    border-radius: 4px;
    z-index: 2;
    h2 {
        margin-top: 0;
    }
}
.col-signup {
    background: $sets-laromedel-lightblue;
    padding: 40px;
    width: 400px;
    color: #000;
}


/* Assets */
.asset-grid {
    display: flex;
    flex-wrap: nowrap;
    overflow: scroll;
}
.asset-list {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    .asset {
        display: flex;
        flex-direction: row;
        align-items: center;
        background: #fff;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid #666;
        img {
            height: 60px;
        }
        .title {
            flex-grow: 2;
            margin: 0px 10px;
        }
        &.asset-expired {
            opacity: 0.4;
        }
    
    }
}
.asset-form {
    button {
        margin: 10px 0 0 0;
    }
    img {
        max-height: 200px; 
    }
    .title {
        font-weight: 700;
        line-height: 1.3;
        text-transform: uppercase;
        margin: 10px 0;
    }
    .license-info {
        font-size: 12px;
        color: #666;
    }
}
.asset {
    display: flex;
    flex-direction: row;
    background: $light-color;
    text-align: center;
    transition: all 200ms ease-in-out;
    .license-info {
        display: none;
    }
    .asset-description {
        display: none;
    }
    img {
        opacity: 0.9;
    }
}
.asset:hover {
    background: $light-color;
    color: $dark-color;
    .license-info {
        opacity: 1;
    }
    img {
        opacity: 1;
    }
}

/* Tables */
.table-wrapper {
    margin: 40px 0;
}

/* Table view */
.status {
    height: 40px;
    width: 40px;
    background: $dark-color;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    box-shadow: $box-shadow;
    &.status-active {
        background: green;
    }
    &.status-inactive {
        background: #eee;
        .merchant {
            color: $dark-color;
        }
    }
    &.status-error {
        background: red;
    }

    .merchant {
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        align-self: center;
        color: $light-color;
    }
}

/* Cards */
.card {
    background: #fff;
    box-shadow: $box-shadow;
    padding: 20px 10px;
    &.card-blue {
        background: $blue-color;
        color: #fff;
        a {
            color: rgba(255,255,255,0.8);
        }
    }
}
/* Small list */

.list-small {
    margin: 20px 0;
    font-size: 12px;
}
.small-type {
    font-size: 12px;
    color: #666;
}
/* About */
.stage {
    margin-top: 40px;
}
.stage-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #4f9bb9;
    h1 {
        text-transform: uppercase;
        text-align: center;
        color: #fff;
    }
    &.gymnasiet {
        background: #f0ad4e;    
    }
}
.stage-description {
    padding: 40px;
}
/* Scroll */

.scroll-left{
    float: left; 
    width: 40px;
    height: 320px;
}
.scroll-right{
    float: right; 
    width: 40px;
    height: 320px;
}
.nav-arrow {
    height: 100%;
    font-weight: 700;
}
.asset-scroll-area {
    float: left; 
    width: calc(100% - 80px);
    overflow: hidden;
    /*will change this to hidden later to deny scolling to user*/
    display: flex;
}
.asset-scroll-area > * {
    flex: 0 0 auto;
    width: 200px;
    margin: 10px;
}
.content-area-grid {
    display: flex;
    flex-wrap: wrap;
}
.content-area-grid > * {
    flex: 0 0 auto;
    width: 200px;
    margin: 10px;
}

.btn-petrol {
    background-color: $sets-laromedel-petrol;
    border-color: $sets-laromedel-petrol;
    color: #fff;

    &:hover {
        color: #fff;
        background-color: lighten($sets-laromedel-petrol, 10%);
        border-color: lighten($sets-laromedel-petrol, 10%);
    }
}

.petrol-background {
    background-color: $sets-laromedel-petrol;
    color: #fff;
}

.classWithPad {
    margin-left: 5px;
    margin-right: 5px;
    padding: 5px;
}

.paddedTable td {
    border: 1px solid black;
    padding: 10px;
}

.paddedParagraph p {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-top: 0;
    margin-bottom: 0;
}

.paddedHeading h2, .paddedHeading h3 {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    margin-top: 0;
    margin-bottom: 0;
}